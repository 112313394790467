<template>
  <div class="alarm-rule-container">
    <div class="query-data-area">
      项目名称：
      <Input v-model="filter.appName" placeholder="请输入项目名称" clearable style="width: 200px; margin-right: 10px;"></Input>
      组织名称：
      <Input v-model="filter.name" placeholder="请输入组织名称" clearable style="width: 200px; margin-right: 10px;"></Input>
      <Button type="primary" @click="search">查询</Button>
    </div>
    <div class="alarm-data-area" ref="table1">
      <vxe-table resizable border show-overflow row-key :row-config="{isHover: true}" :key="tabRefresh" :seq-config="{seqMethod}" :data="list" :scroll-y="{enabled: false}" height="auto">
        <vxe-column field="enable" title="是否启用" width="80" header-align="center">
          <template #default="{ row }">
            <div :style="{color: row.enable ? 'green' : 'red'}">{{row.enable ? '启用' : '未启用'}}</div>
          </template>
        </vxe-column>
        <vxe-column field="appName" title="项目名称"></vxe-column>
        <vxe-column field="name" title="所属组织" width="200"></vxe-column>
        <vxe-column field="area" title="所在地区" width="150"></vxe-column>
        <vxe-column field="lng" title="中心经度" width="180"></vxe-column>
        <vxe-column field="lat" title="中心纬度" width="180"></vxe-column>
        <vxe-column field="linkman" title="联系人" width="150" header-align="center"></vxe-column>
        <vxe-column field="mobile" title="联系方式" width="150" header-align="center"></vxe-column>
        <vxe-column>
          <template #header>
            <Button v-if='funCodes(9901)' size="small" type="primary" style="margin-right: 5px" @click="handleAdd(row)">新增</Button>
          </template>
          <template #default="{ row }">
            <Button v-if='funCodes(9902)' size="small" type="primary" style="margin-right: 5px" @click="handleEdit(row)">编辑</Button>
            <Button v-if='funCodes(9500)' size="small" type="info" style="margin-right: 5px" @click="handleRole(row)">角色管理</Button>
            <Button v-if='funCodes(9510)' size="small" type="info" style="margin-right: 5px" @click="handleUser(row)">用户管理</Button>
            <Button v-if='funCodes(9903)' size="small" :type="row.enable ? 'warning' : 'success'" style="margin-right: 5px" @click="handleEnable(row)">{{row.enable ? '禁用' : '启用'}}</Button>
            <!-- <Button v-if='funCodes(9904)' size="small" type="error" @click="handleDelete(row)">删除</Button> -->
          </template>
        </vxe-column>
      </vxe-table>
    </div>
    <vxe-pager :layouts="pageLayouts" :current-page.sync="filter.index" :page-size.sync="filter.size" :total="total" :page-sizes='pageSizes' @page-change="handlePageChange"></vxe-pager>
    <ModalCustomerEdit v-model="showEditModal" :item="editItem" @saved="itemSaved" />
    <ModalRoleList v-model="showRoleModal" :item="editItem" />
    <ModalUserList v-model="showUserModal" :item="editItem" />
  </div>
</template>
<script>
import { mapState, mapGetters } from 'vuex'
import ModalCustomerEdit from './ModalCustomerEdit'
import ModalRoleList from './role/ModalRoleList'
import ModalUserList from './user/ModalUserList'
export default {
  name: 'SiteManageCust',
  components: {
    ModalCustomerEdit,
    ModalRoleList,
    ModalUserList,
  },
  props: {
  },
  data() {
    return {
      list: [],
      total: 0,
      tabHeight: 600,
      editItem: { isAdd: true, data: {} },
      showEditModal: false,
      showRoleModal: false,
      showUserModal: false,
      filter: {
        name: '',
        appName: '',
        appType: '',
        isNewMode: false,
        index: 1,
        size: 20,
      },
      treeList: [],
      showTreeList: [],
      keyword: "",
      tabRefresh: 0,
      profiles: [],
    }
  },
  computed: {
    ...mapGetters('auth', ['funCodes']),
    ...mapState('cmd', ['windowOnResize']),
    ...mapState('auth', ['token', 'appType', 'isNewMode']),
    ...mapState('common', ['pageLayouts', 'pageSizes']),
  },
  watch: {
    windowOnResize() {
      this.setTableHeight();
    },
  },
  mounted: function () {
    setTimeout(this.setTableHeight, 100);
    this.search();
  },
  destroyed: function () {
  },
  methods: {
    handlePageChange({ currentPage, pageSize }) {
      this.filter.index = currentPage
      this.filter.size = pageSize
      this.getList()
    },
    search: function(){
      this.filter.index = 1;
      this.getList();
    },
    getProfileName: function(row){
      if(!row.enable)return '-';
      if(!row.profile)return '默认风格';
      let ps = this.profiles.filter(p => p.code == row.profile);
      if(ps.length == 0)return '默认风格';
      return ps[0].name;
    },
    setTableHeight: function () {
      this.tabHeight = this.$refs.table1.clientHeight - 0;
    },
    handleRole: function (params) {
      if (!params.enable) {
        this.$Message.warning('请先启用应用');
        return;
      }
      this.editItem = params;
      this.showRoleModal = true;
    },
    handleUser: function (params) {
      if (!params.enable) {
        this.$Message.warning('请先启用应用');
        return;
      }
      this.editItem = params;
      this.showUserModal = true;
    },
    handleAdd: function (params) {
      this.editItem = { isAdd: true, data: params };
      this.showEditModal = true;
    },
    handleEdit: function (params) {
      this.editItem = { isAdd: false, data: params};
      this.showEditModal = true;
    },
    itemSaved: function () {
      this.getList();
    },
    handleEnable: function (params) {
      this.$Modal.confirm({
        title: "提示",
        content: `确定要${params.enable ? '禁用' : '启用'}组织[${params.name}]的应用吗?`,
        onOk: async () => {
          this.$axios.post(`site/customer/EnableApp`, { id: params.id, enable: !params.enable }).then(res => {
            if (res.code != 0) {
              // this.$Message.error(res.data);
            } else {
              // this.getList();
              params.enable = !params.enable;
              this.$Message.info(!params.enable ? '禁用成功' : '启用成功');
            }
          });
        }
      });
    },
    handleDelete: function (params) {
      this.$Modal.confirm({
        title: "提示",
        content: `确定要删除[${params.name}]吗?`,
        onOk: async () => {
          this.$axios.post(`site/customer/DeleteCust`, { id: params.id }).then(res => {
            if (res.code != 0) {
              // this.$Message.error(res.data);
            } else {
              this.$Message.info('删除成功');
              this.getList();
            }
          });
        }
      });
    },
    getCustItem: function (id) {
      let cs = this.treeList.filter(p => p.id == id);
      if (cs.length == 0) return null;
      return cs[0];
    },
    getList: function () {
      this.filter.appType = this.appType;
      this.filter.isNewMode = this.isNewMode;
      this.$axios.post(`site/customer/QueryCust`, this.filter).then(res => {
        if (res.code != 0) return
        this.$set(this, 'list', res.data.list);
        this.total = res.data.count;
      });
    },
    seqMethod({ row }) {
      return `${row.level}级：${row.name}`
    },
    keywordBut() {
      this.initTreeData();
      if (this.keyword == "") {
        this.$set(this, 'showTreeList', this.treeList);
      }
      let arr = [];
      this.treeList.forEach(el => {
        if (el.name.indexOf(this.keyword) > -1) {
          arr.push(el);
        }
      });
      this.$set(this, 'showTreeList', arr);
      this.tabRefresh++
    },
    initTreeData: function () {
      this.treeList.forEach(el => {
        el.level = 1;
        let cs = this.treeList.filter(p => p.id == el.parentId);
        if (cs.length > 0) {
          el.level = cs[0].level + 1;
        }
      });
      this.treeList.forEach(el => {
        if (isNaN(el.level)) {
          this.initTreeData();
        }
      });
    },
  }
}
</script>
<style scoped>
.alarm-rule-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-content: stretch;
  /* padding: 10px; */
  /* padding-right: 40px; */
  /* border: solid 1px red; */
}
.query-data-area {
  height: 45px;
  flex: none;
  display: flex;
  align-items: center;
  /* border: solid 1px rgba(143, 138, 138, 0.452); */
  margin-bottom: 5px;
}
.alarm-data-area {
  /* border: solid 1px red; */
  height: 100px;
  flex: auto;
  /* border: solid 1px #DCDEE2; */
  border-radius: 6px;
  /* padding-left: 10px; */
}
.station-alarm-tabs {
  height: 30px;
  flex: none;
  display: flex;
}
.station-alarm-tab {
  padding: 3px 8px;
  margin: 3px 3px 0 3px;
  border: solid 1px lightgray;
  border-radius: 5px 5px 0 0;
  cursor: pointer;
  background-color: white;
}
.tab-active {
  border: solid 1px rgb(135, 159, 224);
  background-color: rgb(135, 159, 224);
  color: white;
}
.station-alarm-tabcontent {
  height: 500px;
  flex: auto;
  /* border: solid 1px red; */
}
/* .box-body{
  height: 0;
  flex: auto;
  margin-top: 5px;
  border: solid 1px red;
  overflow-x: hidden;
  overflow-y: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
  position: relative;
}
.box-body::-webkit-scrollbar {
  display: none;
} */
</style>