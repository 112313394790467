<template>
  <Modal v-model="showModal" :mask-closable="false" width="1200px">
    <div slot="header">编辑项目信息</div>
    <div class="edit-area">
      <Form ref="form" class="form-area" :model="form" label-position="top">
        <FormItem prop="appName" label="项目名称">
          <div style="display: flex;align-items:center;">
            <Input type="text" maxlength="20" v-model="form.appName" placeholder="请输入名称"></Input>
          </div>
        </FormItem>
        <FormItem prop="name" label="组织名称">
          <Input type="text" maxlength="20" v-model="form.name" placeholder="请输入名称"></Input>
        </FormItem>
        <FormItem prop="linkman" label="联系人">
          <Input type="text" maxlength="20" v-model="form.linkman" placeholder="请输入联系人"></Input>
        </FormItem>
        <FormItem prop="mobile" label="联系方式">
          <Input type="text" maxlength="20" v-model="form.mobile" placeholder="请输入联系方式"></Input>
        </FormItem>
        <FormItem prop="entryCode" label="项目语言">
          <div style="display: flex;align-items:center;">
            <!-- <Input type="text" maxlength="20" v-model="form.entryCode" placeholder="请输入应用入口码" style="width: 250px;margin-right: 10px"></Input> -->
            <!-- <Select v-model="form.profile" style="width: 250px;margin-right: 10px">
              <template v-for="(item, key) in profiles">
                <Option :value="item.code" :key="key">{{item.name}}</Option>
              </template>
            </Select> -->
            <Select v-model="form.lang">
              <template v-for="(item, key) in langTypes">
                <Option :value="key" :key="key">{{item}}</Option>
              </template>
            </Select>
          </div>
        </FormItem>
        <FormItem prop="area" label="地区及中心点">
          <div style="display: flex;align-items:center;">
            <a href="#" slot="extra" @click.prevent="setMapCenter" style="margin-right:10px">
              <Icon type="ios-loop-strong"></Icon>
              设置
            </a>
            {{form.area}} - {{form.lat}} - {{form.lng}}
            <ModalSelectMapPoint v-model="showMapModal" :custCenter="{area: form.area, lat: form.lat, lng: form.lng}" @posSelected="posSelected" />
          </div>
        </FormItem>
        <FormItem prop="logo" label="可视化LOGO  (图片尺寸700*92px  5M以内)">
          <div class="logo-upload" @click="selectImg(false)">
            <img v-if="form.logo" :src="`//${domains.trans}/sys/setting/FileContent?id=${form.logo}&auth=${token}`" />
            <input :ref="`fileInput`" type="file" accept="image/png,image/gif,image/jpg,image/jpeg," @input="fileSelected($event, false)" />
          </div>
        </FormItem>
        <FormItem prop="sysLogo" label="系统LOGO  (图片尺寸700*92px  5M以内)">
          <div class="logo-upload" @click="selectImg(true)">
            <img v-if="form.sysLogo" :src="`//${domains.trans}/sys/setting/FileContent?id=${form.sysLogo}&auth=${token}`" />
            <input :ref="`fileInput1`" type="file" accept="image/png,image/gif,image/jpg,image/jpeg," @input="fileSelected($event, true)" />
          </div>
        </FormItem>
      </Form>
      <div class="fun-area">
        <MenuNode v-for="fun in functions" :key="`fun-${fun.code}`" :node="fun"></MenuNode>
      </div>
    </div>
    <div slot="footer">
      <Button style="margin-right: 8px" @click="cancel">取消</Button>
      <Button type="primary" :loading="loading" @click="ok">保存</Button>
    </div>
  </Modal>
</template>
<script>
import { mapState } from 'vuex'
import ModalSelectMapPoint from '@/views/common/modals/ModalSelectMapPoint'
import MenuNode from './checktree/MenuNode'
export default {
  name: 'ModalContactEdit',
  components: {
    ModalSelectMapPoint,
    MenuNode,
  },
  model: {
    prop: 'value',
    event: 'showChanged'
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    item: {
      type: Object,
      default() { return {}; }
    },
  },
  data() {
    return {
      showModal: this.value,
      showMapModal: false,
      loading: false,
      isAdd: false,
      types: [],
      parentName: '',
      functions: [],
      devices: [],
      form: {
        id: 0,
        name: '',
        linkman: '',
        mobile: '',
        appName: '',
        // entryCode: '',
        area: '',
        lat: 0,
        lng: 0,
        logo: '',
        sysLogo: '',
        lang: 'zh_cn',
        ops: [],
        appType: '',
        isNewMode: false,
      },
      langTypes: {
        'zh_cn': '中文简体',
        'en_us': '美国英语',
      },
      rules: {
        name: { required: true, type: 'string', max: 50, message: '组织名称不能为空且长度不能超过50', trigger: 'blur' },
        linkman: { required: true, type: 'string', max: 30, message: '联系人不能为空且长度不能超过30', trigger: 'blur' },
        mobile: { required: true, type: 'string', max: 30, message: '联系方式不能为空且长度不能超过30', trigger: 'blur' },
        appName: { required: true, type: 'string', max: 30, message: '应用名称不能为空且长度不能超过30', trigger: 'blur' },
        entryCode: { required: true, type: 'string', max: 30, message: '应用入口码不能为空且长度不能超过20', trigger: 'blur' },
        area: { required: true, type: 'string', max: 30, message: '所在城市不能为空且长度不能超过30', trigger: 'blur' },
        logo: { required: true, type: 'string', max: 32, message: '请选择组织应用的LOGO', trigger: 'blur' },
      },
    }
  },
  watch: {
    value(newVal) {
      this.showModal = newVal;
      if (newVal) {
        // console.log('modal level edit mounted', this.item)
        this.isAdd = this.item.isAdd;
        if (!this.isAdd) {
          this.form.id = this.item.data.id;
          // this.form.parentId = this.item.data.parentId;
          // this.parentName = this.item.parent.name;
          this.form.name = this.item.data.name;
          this.form.linkman = this.item.data.linkman;
          this.form.mobile = this.item.data.mobile;
          this.form.appName = this.item.data.appName;
          // this.form.entryCode = this.item.data.entry;
          // this.form.profile = this.item.data.profile;
          this.form.area = this.item.data.area;
          this.form.lat = this.item.data.lat;
          this.form.lng = this.item.data.lng;
          this.form.logo = this.item.data.logo;
          this.form.sysLogo = this.item.data.sysLogo;
          this.form.lang = this.item.data.lang || 'zh_cn';
          this.form.ops = [];
        } else {
          this.form.id = 0;
          // this.form.parentId = this.item.parent.id;
          // this.parentName = this.item.parent.name;
          this.form.name = '';
          this.form.linkman = '';
          this.form.mobile = '';
          this.form.appName = '';
          // this.form.profile = '';
          // this.form.entryCode = '';
          this.form.area = '深圳市';
          this.form.lat = 22.63056743737606;
          this.form.lng = 114.05829921047837;
          this.form.logo = '';
          this.form.sysLogo = '';
          this.form.lang = 'zh_cn';
          this.form.ops = [];
        }
        // if(!this.form.profile){
        //   this.form.profile = 'default';
        // }
        this.initFunctions();
      }
    },
    showModal(val) {
      this.$emit('showChanged', val);
    },
  },
  computed: {
    ...mapState('auth', ['token', 'isNewMode', 'appType']),
  },
  mounted: function () {
  },
  methods: {
    selectAll: function (evt) {
      for (let fun of this.functions) {
        for (let op of fun.operations) {
          op.chk = evt;
        }
      }
    },
    posSelected: function (pos) {
      this.form.lat = pos.lat;
      this.form.lng = pos.lng;
      this.form.area = pos.city;
    },
    initFunctions: function () {
      this.$axios.post(`site/customer/QueryCustFuncs`, { custId: this.form.id }).then(res => {
        if (res.code != 0) {
          // this.$Message.error(res.data);
        } else {
          this.$set(this, 'functions', res.data.modules);
        }
      });
    },
    setMapCenter: function () {
      this.showMapModal = true;
    },
    selectFunAll: function (evt, fun) {
      for (let op of fun.operations) {
        op.chk = evt;
      }
    },
    selectImg: function (isSysLog) {
      if(isSysLog){
        let fs = this.$refs.fileInput1;
        if (!fs) return;
        fs.click();
        return;
      }
      let fs = this.$refs.fileInput;
      if (!fs) return;
      fs.click();
    },
    fileSelected: async function (e, isSysLog) {
      // console.log('file selected', e, isSysLog);
      if (e.target.files.length == 0) return;
      let file = e.target.files[0];
      if (file.size / 1024 / 1024 > 5) {
        file.value = "";
        this.$Message.error("图片大小不能大于5M");
        return false;
      }
      this.limitFileWH(700, 92, file).then((res) => {
        if (res) {
          this.uploadImg(file, isSysLog)
        }
      })

    },
    uploadImg: async function (file, isSysLog) {
      var formData = new FormData();
      formData.append(file.name, file);
      // let res = await this.$axios.post(`//${this.domains.trans}/sys/setting/FileSave`, formData);
      let res = await this.$axios.request({
        url: `//${this.domains.trans}/sys/setting/FileSave`,
        method: 'post',
        data: formData,
        responseType: 'json',
        myType: 'file'
      });
      if(isSysLog){
        this.$refs.fileInput1.value = '';
      }else{
        this.$refs.fileInput.value = '';
      }
      if (res.code !== 0) {
        return;
      }
      if(isSysLog){
        this.form.sysLogo = res.data;
      }else{
        this.form.logo = res.data;
      }
    },
    limitFileWH(E_width, E_height, file) {
      const _this = this
      let imgWidth = ''
      let imgHight = ''
      const isSize = new Promise(function (resolve, reject) {
        const width = E_width
        const height = E_height
        const _URL = window.URL || window.webkitURL
        const img = new Image()
        img.onload = function () {
          imgWidth = img.width
          imgHight = img.height
          const valid = img.width === width && img.height === height
          valid ? resolve() : reject()
        }
        img.src = _URL.createObjectURL(file)
      }).then(() => {
        return true
      }, () => {
        _this.$Message.error('上传图片的尺寸应为' + E_width + '*' + E_height + '，当前上传图片的尺寸为：' + imgWidth + '*' + imgHight)
        return false
      })
      return isSize
    },
    getChecks: function(chks, func){
      if(func.isLeaf){
        chks.push(...func.checks);
      }else{
        for(let f of func.children){
          this.getChecks(chks, f);
        }
      }
    },
    ok: async function () {
      // let result = await this.$refs.form.validate();
      // if (!result) {
      //   return;
      // }
      let chks = [];
      for (let fun of this.functions) {
        this.getChecks(chks, fun);
      }
      // console.log('get checks', chks);
      this.form.ops = chks;
      if (this.form.ops.length == 0) {
        this.$Message.warning('请选择项目权限');
        return;
      }
      this.form.appType = this.appType;
      this.form.isNewMode = this.isNewMode;
      this.$axios.post(`site/customer/SaveCust`, this.form).then(res => {
        if (res.code != 0) {
          // this.$Message.error(res.data);
        } else {
          this.$Message.info('保存成功');
          this.showModal = false;
          this.$emit('saved');
        }
      })
    },
    cancel() {
      // console.log('close modal',this.showModal)
      this.showModal = false;
      // this.$refs.form.resetFields();
    },
  }
}
</script>
<style scoped>
.form-item-group {
  border: solid 1px rgba(129, 124, 124, 0.377);
  border-radius: 12px;
  margin-bottom: 15px;
}
.group-header {
  /* border: solid 1px blue; */
  background-color: rgba(221, 216, 216, 0.808);
  margin-bottom: 15px;
  height: 35px;
  border-radius: 12px 12px 0 0;
  line-height: 35px;
  font-weight: bold;
  padding: 0 10px;
}
.edit-area {
  /* border: solid 1px blue; */
  width: 100%;
  height: 865px;
  display: flex;
}
.form-area {
  width: 410px;
  flex: none;
}
.fun-area {
  width: 400px;
  height: 100%;
  flex: auto;
  margin-left: 20px;
  border: solid 1px rgba(216, 199, 199, 0.438);
  border-radius: 6px;
  padding: 10px;
  overflow: auto;
  position: relative;
}
.chk-all {
  position: absolute;
  right: 10px;
  top: 10px;
}
.logo-upload {
  width: 410px;
  height: 114px;
  border: solid 1px gray;
  cursor: pointer;
}
.logo-upload input {
  visibility: hidden;
}
.logo-upload img {
  width: 410px;
  height: 114px;
}
.fun-list {
  display: flex;
  /* list-style: none; */
  flex-direction: column;
  margin: 8px 15px;
}
.chk-list {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
}
li {
  margin: 0 5px;
}
</style>