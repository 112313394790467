<template>
  <Modal v-model="showModal" :mask-closable="false" width="1000px">
    <div slot="header">用户加入项目</div>
    <div class="user-edit-content">
      <Card class="cust-fields">
        <p slot="title">
          <Icon type="ios-film-outline"></Icon>
          基本信息
        </p>
        <Form ref="form" :model="form" :label-width="80">
          <FormItem prop="customerId" label="用户名">
            <Input type="text" maxlength="20" v-model="info.userCode" readonly placeholder="请输入名称"></Input>
          </FormItem>
          <FormItem prop="code" label="姓名">
            <Input ref="code" type="text" maxlength="20" v-model="info.userName" readonly placeholder="请输入登录名"></Input>
          </FormItem>
          <FormItem prop="mobile" label="手机号">
            <Input ref="mobile" type="text" maxlength="20" v-model="info.userMobile" readonly placeholder="请输入手机号"></Input>
          </FormItem>
          <FormItem prop="password" label="">
            <span style="font-size: 20px;">加入项目</span>
          </FormItem>
          <FormItem prop="name" label="项目名称">
            <Input ref="name" type="text" maxlength="20" v-model="info.appName" readonly placeholder="请输入姓名"></Input>
          </FormItem>
          <FormItem prop="name" label="组织名称">
            <Input ref="name" type="text" maxlength="20" v-model="info.custName" readonly placeholder="请输入姓名"></Input>
          </FormItem>
          <FormItem prop="name" label="所在地区">
            <Input ref="name" type="text" maxlength="20" v-model="info.custArea" readonly placeholder="请输入姓名"></Input>
          </FormItem>
        </Form>
      </Card>
      <Card class="cust-fields" style="margin: 0 5px">
        <p slot="title">
          <Icon type="ios-film-outline"></Icon>
          操作权限
        </p>
        <vxe-table empty-text="无可选角色" border resizable show-overflow show-header-overflow keep-source
          ref="chkTable" :data="roles" :height="500" :row-config="{isHover: true}" :checkbox-config="{checkField: 'check',}" row-id="id">
          <vxe-column width="50" type="checkbox" field="check" fixed="left"></vxe-column>
          <vxe-column field="name" title="名称" header-align="center"></vxe-column>
        </vxe-table>
      </Card>
      <Card class="cust-fields">
        <p slot="title">
          <Icon type="ios-film-outline"></Icon>
          数据权限
        </p>
        <div style="height: 500px;overflow-x: hidden;overflow-y: auto;border: solid 0 red">
          <Tree ref="groupTree" :data="groups" show-checkbox check-strictly check-directly @on-check-change="noceCheckChanged"></Tree>
        </div>
      </Card>
      <ModalUsbKeyCheck v-model="showUsbKeyCheckModal" :item="editItem" />
    </div>
    <div slot="footer">
      <Button style="margin-right: 8px" @click="cancel">取消</Button>
      <Button type="primary" :loading="loading" @click="ok">保存</Button>
    </div>
  </Modal>
</template>
<script>
import {mapState} from 'vuex'
import ModalUsbKeyCheck from './ModalUsbKeyCheck'
export default {
  name: 'ModalUserEdit',
  components:{
    ModalUsbKeyCheck
  },
  model: {
    prop: 'value',
    event: 'showChanged'
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    item: {
      type: Object,
      default(){ return {user: {}, cust: {}};}
    },
  },
  data () {
    return {
      showModal: this.value,
      showUsbKeyCheckModal: false,
      loading: false,
      isAdd: false,
      custName: '',
      index: -1,
      editItem: {},
      allGroups: [],
      groups: [],
      roles: [],
      info:{
        userCode: '',
        userName: '',
        userMobile: '',
        appName: '',
        custName: '',
        custArea: ''
      },
      form: {
        userId: 0,
        custId: 0,
        roles: [],
        groups: [],
      },
      rules: {
        code: { required: true, type: 'string', max: 20, message: '登录名不为空且长度不超过20', trigger: 'blur' },
        name: { required: true, type: 'string', max: 20, message: '姓名不为空且长度不超过20', trigger: 'blur' },
        mobile: { required: true, type: 'string', max: 20, message: '手机号不为空且长度不超过20', trigger: 'blur' },
      },
      needUsbKey: false,
    }
  },
  computed: {
    ...mapState('auth', ['organs', 'customers']),
  },
  watch: {
    value(newVal) {
      this.showModal = newVal;
      if(this.showModal){
        // console.log('set role and group', this.item);
        this.info.userCode = this.item.user.code;
        this.info.userName = this.item.user.name;
        this.info.userMobile = this.item.user.mobile;
        this.info.custName = this.item.cust.name;
        this.info.appName = this.item.cust.appName;
        this.info.custArea = this.item.cust.area;
        this.getUserRoles();
      }
    },
    showModal(val){
      this.$emit('showChanged', val);
    },
  },
  methods: {
    noceCheckChanged: function(nodes, node){
      // console.log('node check change', node, nodes)
      if(!node.checked)return;
      this.unchecksubs(node);
      for(let n of nodes){
        let subIds = this.getSubNodeIds(n);
        // console.log('get sub node ids', n.id, n.title, subIds);
        if(subIds.includes(node.id)){
          this.$set(n, 'checked', false);
        }
      }
    },
    getSubNodeIds: function(node){
      let ids = [];
      for(let n of node.children){
        ids.push(n.id);
        ids.push(...this.getSubNodeIds(n));
      }
      return ids;
    },
    unchecksubs: function(node){
      if(!node.children)return;
      for(let n of node.children){
        // n.checked = false;
        this.$set(n, 'checked', false);
        this.unchecksubs(n);
      }
    },
    getUserRoles: function(){
      if(this.item.cust.id <= 0){
        this.$set(this, 'roles', []);
        this.$set(this, 'groups', []);
        return;
      }
      this.$axios.post(`site/customer/QueryUserAgents`, {id: this.item.user.id, custId: this.item.cust.id}).then(res => {
        this.$set(this, 'groups', res.data.groups);
        this.$set(this, 'roles', res.data.roles);
      })
    },
    ok: async function () {
      // let result = await this.$refs.form.validate();
      // if(!result){
      //   // this.hideLoading();
      //   return;
      // }
      let chkNodes = this.$refs.groupTree.getCheckedNodes();
      let userGroups = [];
      for(let cn of chkNodes){
        // console.log('get check group', cn.id, cn.title)
        userGroups.push(cn.id);
      }
      let userRoles = [];
      for(let role of this.roles){
        if(role.check){
        // console.log('get check role', role.id, role.name)
          userRoles.push(role.id);
        }
      }
      if(userRoles.length == 0){
        this.$Message.warning('必须选择操作权限');
        return;
      }
      if(userGroups.length == 0){
        this.$Message.warning('必须选择数据权限');
        return;
      }
      this.form.roles = userRoles;
      this.form.groups = userGroups;
      this.form.userId = this.item.user.id;
      this.form.custId = this.item.cust.id;
      this.loading = true;
      let res = await this.$axios.post(`site/customer/SetUserAgent`, this.form);
      this.loading = false;
      if(res.code !== 0)return;
      this.$Message.info('保存成功');
      setTimeout(() => {
        this.showModal = false;
        this.$emit('saved');
      }, 800);
    },
    cancel () {
      this.showModal = false;
    },
  }
}
</script>
<style scoped>
.user-edit-content{
  display: flex;
}
.cust-fields{
  width: 300px;
  flex: auto;
}
  .form{
    height: calc(100%);
    overflow: auto;
  }
  .readonly{
    background: #eee
  }
</style>